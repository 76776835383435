// strapi post sections types
export const paragraphType:string = "paragraph";
export const listType:string = "list";
export const imageType:string = "image";
export const quoteType:string = "quote";
export const videoType:string = "video";
export const htmlType:string = "raw";
export const tableType:string = "table";
export const headerType:string = "header";

