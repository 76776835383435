import React, { useState, useEffect} from "react";
import { headerType } from "../../constants";
import "./header.scss";

type tItem = {
  type: string;
  level: string|number;
  text: string;
};

const Header = props => {

  const item:tItem = props.item;
  const [ Header, setHeader ] = useState<string>("h2");

  useEffect(() => {
    if (item.type === headerType && item.level) {
      setHeader("h" + item.level);
    }
  }, [item]);

  return (
    <>
      {item.type === headerType &&
        <Header
          className={`header-${item.level}`}
          dangerouslySetInnerHTML={{__html: item.text}}
        />
      }
    </>
  );
};

export default Header;