import React from "react";
import { paragraphType } from "../../constants";
import "./text.scss";

type tItem = {
  type: string;
  text: string;
  lead: string;
};

export const Text = props => {
  const item:tItem = props.item;
  return (
    <>
      {item.type === paragraphType &&
        <p
          className={item.lead && "lead-text" || ""}
          dangerouslySetInnerHTML={{__html: item.text}}
        />
      }
    </>
  );
};

export default Text;