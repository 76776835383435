import React, { useEffect } from 'react';
import { quoteType } from '../../constants';
import { siteUrl } from '../../../../config/config';
import './catchyQuote.scss';

type tItem = {
    type: string;
    image: string;
    name: string;
    post: string;
    text: string;
};

const CatchyQuote = props => {
    const item: tItem = props.item;

    return (
        <>
            {item.type === quoteType && (
                <div className="article-author">
                    <div className="article-author__top">
                        {item.image && (
                            <div className="article-author__foto">
                                <img src={siteUrl + item.image} alt="" />
                            </div>
                        )}
                        <div className="article-author__data">
                            <div
                                className="article-author__name"
                                dangerouslySetInnerHTML={{
                                    __html: item.name,
                                }}
                            ></div>
                            <div
                                className="article-author__pos"
                                dangerouslySetInnerHTML={{ __html: item.post }}
                            ></div>
                        </div>
                    </div>
                    <div
                        className="article-author__text"
                        dangerouslySetInnerHTML={{ __html: item.text }}
                    ></div>
                </div>
            )}
        </>
    );
};

export default CatchyQuote;
