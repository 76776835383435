import React from "react";
import { tableType } from "../../constants";
import "./table.scss";

type tTr = string[];

type tItem = {
  type: string;
  content: tTr[];
};

const Table = props => {
  const item:tItem = props.item;
  return (
    <>
      {item.type === tableType &&
        <div className="table-block">
          {(item.content && item.content.length > 0) && (
            <table>
              {item.content.map((tr, i) => (
                <tr>
                  {tr && tr.length > 0 && tr.map((td,j) => (
                    <td dangerouslySetInnerHTML={{__html: td}}/>
                  ))}
                </tr>
              ))}
            </table>
          )}
        </div>
      }
    </>
  );
};

export default Table;