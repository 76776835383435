import React from "react";
import { videoType } from "../../constants";
import "./video.scss";

type tItem = {
  type: string;
  video: string;
  caption: string;
};

const Video = props => {
  const item:tItem = props.item;
  return (
    <>
      {item.type === videoType &&
        <div className="youtube-video">
          <div
            className="video-iframe"
            dangerouslySetInnerHTML={{__html: item.video}}
          />
          <div className="video-caption">{item.caption}</div>
        </div>
      }
    </>
  );
};

export default Video;