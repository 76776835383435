import React from "react";
import { htmlType } from "../../constants";
import "./html.scss";

type tItem = {
  type: string;
  text: string;
};

const Html = props => {
  const item:tItem = props.item;
  return (
    <>
      {item.type === htmlType &&
        <div
          className="html-block"
          dangerouslySetInnerHTML={{__html: item.text}}
        />
      }
    </>
  );
};

export default Html;