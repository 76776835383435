import React from "react";
import { imageType } from "../../constants";
import { siteUrl } from "../../../../config/config";
import "./image.scss";

type tItem = {
  type: string;
  caption: string;
  image: string;
};

const Image = (props) => {
  const item:tItem = props.item;
  return (
    <>
      {item.type === imageType && (
        <div className="image-block">
          <img
            alt={item.caption}
            src={siteUrl + item.image}
          />
          <p>{item.caption}</p>
        </div>
      )}
    </>
  );
};

export default Image;
