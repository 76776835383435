import React from "react";
import { listType } from "../../constants";
import "./list.scss";

type tItem = {
  type: string;
  list: string[];
  listStyle: string;
};

export const List = props => {
  const item:tItem = props.item;
  return (
    <>
      {item.type === listType &&
        <ul className={item.listStyle}>
          {item.list && item.list.length > 0 && item.list.map(listItem => (
            <li
              dangerouslySetInnerHTML={{__html: listItem}}
            />
          ))}
        </ul>
      }
    </>
  );
};

export default List;